import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  apiGetCardPool,
  apiGetGoodsDetail,
  apiGetMatchById,
  apiGetOpenId,
  apiGetPayConfigList,
  apiMarketOfferOrder,
  apiMarketVerifyCode,
  apiOssUpload,
  apiPayAndSubmitOrder,
  apiSubmitOrder,
} from '../api';
import AppBar from '../components/appBar';
import Empty from '../components/empty';
import Loading from '../components/loading';
import MessageDialog from '../components/messageDialog';
import MCityPicker from '../components/m_city_picker/m_city_picker';
import Page from '../components/page';
import ProtocolDialog from '../components/protocolDialog';
import MCheckbox from '../components/radioBox';
import MSelectbox from '../components/select';
import SelectPhoneDialog from '../components/selectPhoneDialog';
import main from '../main';
import { Goods, PayConfig } from '../types';
import showPayPage, { PayType } from '../utils/pay';
import { copyToClipboard, isWeixinEnv, parseLocationParams, showToast } from '../utils/utils';
import { protocolData } from './protocolPage';

let loading = false;

export default function DetailPage() {
  const ref = useRef<{ timer?: NodeJS.Timeout; count?: number }>({});
  let location = useLocation();
  const navigate = useNavigate();
  const [goodsDetail, setGoodsDetail] = useState<Goods>();
  const [loadError, setLoadError] = useState('');
  // 已选卡号
  const [selectCard, setSelectCard] = useState<{
    activatePhone: string;
    checkCode: string;
    phoneSessionId?: string;
    phoneTag?: string;
    numberPoolId?: string;
    numberId?: string;
  }>();
  // 身份证名
  const [cardName, setCardName] = useState('');
  // 身份证号
  const [cardNum, setCardNum] = useState('');
  // 收件人手机号
  const [phone, setPhone] = useState('');
  // 验证码
  const [verifyCode, setVerifyCode] = useState('');
  const [sendCaptcha, setSendCaptcha] = useState(0);
  // 省市区
  const [province, setProvince] = useState('');
  // 市区
  const [city, setCity] = useState('');
  // 区
  const [area, setArea] = useState('');
  // 地址
  const [address, setAddress] = useState('');
  // 运营商
  const [operatorsType, setoperatorsType] = useState('');
  // 身份证头像照片
  const [cardAvatar, setCardAvatar] = useState<string>();
  // 身份证国徽照片
  const [cardNationalEmblem, setNationalEmblem] = useState<string>();
  // 免冠正面照片
  const [handPhoto, setHandPhoto] = useState<string>();
  // 已选同意协议
  const [checkProtocol, setCheckProtocl] = useState(false);
  // 显示协议弹窗
  const [showProtocol, setShowProtocol] = useState<string>();
  // 显示选择卡号弹窗
  const [showSelectCardDialog, setShowSelectCardDialog] = useState(false);
  // 运营商数据
  const [operatorsList, setoperatorsList] = useState<{ value: string; label: string }[]>([]);
  // 是否隐藏appbar 默认不隐藏 app内嵌入网页时可设置 h=1 隐藏
  const [hideAppBar, setHideAppBar] = useState(false);
  /**
   * activatePhone :  17260779503
   * checkCode : dGcoEdX1DCNUYjXy4
   */
  const [cardPool, setCardPool] = useState([]);
  const [urlQueryParams, setUrlQueryParams] = useState<any>({});
  // 支付配置列表
  const [payConfigList, setPayConfigList] = useState<PayConfig[]>([]);
  const [payType, setPayType] = useState('');
  // 微信支付配置准备完成
  const [wxReady, setWxReady] = useState(false);
  // 判断视频上的播放图片是否显示
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(true);
  const [selectPhoneFlag, setselectPhoneFlag] = useState<boolean>(true);
  // 短信验证码orderId
  const [smsKey, setSmsKey] = useState();
  // 路路通productId
  const [productId, setproductId] = useState<string>('');
  // 接口报错msg
  const [messageDialogShow, setmessageDialogShow] = useState<string>('');
  // 地址库数据
  const [matchList, setMatchList] = useState([]);

  useEffect(() => {
    main.enterUrl = window.location.href;
    // 微信code 和商品code参数冲突，改商品code为id自动跳转
    let _p = parseLocationParams(location.search) ?? {};
    setUrlQueryParams(_p);

    if (_p.code?.length === 10) {
      let queryStr = location.search.replaceAll('code=', 'id=');
      window.location.replace(window.location.origin + window.location.pathname + '/' + queryStr);
    } else if (isWeixinEnv()) {
      // 获取支付列表
      loadPayConfigList(_p.id);
    } else {
      // 非微信环境内（浏览器、支付宝直接获取商品详情与支付方式）
      loadGoodsDetail(_p.id);
      loadPayConfigList(_p.id);
    }
  }, []); // eslint-disable-line

  // 获取OPENID
  async function getOpenId() {
    try {
      let res = await apiGetOpenId(main.code!, main.appId);
      let data = res.data;
      if (data.code === 200) {
        main.openId = data.data;
        console.log('main.openId', main.openId);
        localStorage.setItem(main.appId, main.openId ?? '');

        loadGoodsDetail(urlQueryParams.id);
      }
    } catch (e) {}
  }

  function wxPayReady() {
    if (!isWeixinEnv()) return;
    // @ts-ignore
    if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', () => setWxReady(true), false);
        // @ts-ignore
      } else if (document.attachEvent) {
        // @ts-ignore
        document.attachEvent('WeixinJSBridgeReady', () => setWxReady(true));
      }
    } else {
      setWxReady(true);
    }
  }

  // 获取支付列表
  async function loadPayConfigList(code?: string) {
    console.log('urlQueryParams', urlQueryParams);
    try {
      let res = await apiGetPayConfigList(code ?? urlQueryParams.id);
      let data = res.data;
      if (data.code === 200) {
        let _list = data.data ?? [];
        let arr = [];
        if (isWeixinEnv()) {
          arr = _list.filter((i: { payType: string }) => i.payType.indexOf('wechatJsPay') !== -1);
          main.appId = arr[0]?.appId;
          let param = parseLocationParams(location.search);
          // 对比appid和链接内是否一样
          if (main.appId !== param.appId) {
            // 跳转自身带上appid
            param.appId = main.appId;
            let url = Object.keys(param)
              .map((key) => `${key}=${param[key]}`)
              .join('&');
            let path = window.location.origin + window.location.pathname + `?${url}`;
            window.location.href = path;

            console.log('url---', url, path);
          } else {
            let openId = localStorage.getItem(main.appId);
            if (!!openId) {
              main.openId = openId;
              console.log('---有openId', openId);
              loadGoodsDetail(urlQueryParams.id);
            } else {
              //  判断是否有CODE
              if (!!param.code) {
                main.code = param.code;
                getOpenId();
              } else {
                let redirect_uri = encodeURIComponent(window.location.href);
                // 没有取到缓存openId, 跳微信链接
                document.location.replace(
                  `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${param.appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
                );
              }
              // window.location.href = window.location.origin + `/product` + location.search + `&appId=${main.appId}`;
            }
          }
          // 用appID 取缓存openID 跳转自身带上appid
        } else {
          arr = _list.filter((i: { payType: string }) => i.payType.indexOf('alipay') !== -1);
        }

        setPayConfigList(arr);
        if (arr.length > 0) {
          setPayType(arr[0].payType);
        }
      }
    } catch (e) {}
  }

  async function loadGoodsDetail(code: string) {
    wxPayReady();
    let _p = parseLocationParams(location.search) ?? {};
    console.log('code-----', code, _p);
    try {
      let res = await apiGetGoodsDetail(code ?? _p.id);
      let data = res.data;
      if (data.code === 200) {
        setGoodsDetail(data.data);
        let landingCode = sessionStorage.getItem('landingCode');
        // 带参数的和路路通的都隐藏
        let _hideTitle = _p.h === '1' || !!_p.productId;
        if (_p.productId) {
          setproductId(_p.productId);
        }
        setHideAppBar(_hideTitle);
        if (landingCode) {
          main.shopId = landingCode;
        } else if (data.data?.shopId) {
          main.shopId = (parseInt(data.data?.pageType ?? '0') !== 0 ? data.data.pageType : '') + data.data.shopId;
        }

        if (data.data.supId) {
          getMatchById(data.data.supId);
        }

        if (data.data.ifCampOn === 'Y' && data.data.ifAddress !== 'Y') {
          loadCardPool(data.data.goodsCode);
        }
        // 商品类型不等于号卡时获取运营商数据
        if (data.data?.goodsType !== 'order') {
          let _data = [
            { label: '中国移动', value: 'cmcc' },
            { label: '中国联通', value: 'unicom' },
            { label: '中国电信', value: 'telecom' },
            { label: '中国广电', value: 'cbn' },
          ];
          let list = data.data.operatorsType ? data.data.operatorsType.split(',') : [];
          let _list: any = [];
          list.forEach((item: string) => {
            _data.forEach((i) => {
              if (item === i.value) {
                _list.push(i);
              }
            });
          });
          setoperatorsList(_list);
          setoperatorsType(_list[0]?.value);
        }
      } else if (data.code === 503) {
        setLoadError(data?.msg ?? '暂未找到商品');
      } else {
        // setLoadError(data.msg ?? '暂未找到商品');
        setmessageDialogShow(data.msg ?? '暂未找到商品');
      }
    } catch (e: any) {
      setLoadError(e?.message ?? '暂未找到商品');
    }
  }
  async function getMatchById(supId: string) {
    try {
      let res = await apiGetMatchById(supId);
      let data = res.data;
      if (data.code === 200) {
        setMatchList(data.data);
      } else {
        setMatchList([]);
      }
    } catch (e: any) {
      showToast(e.message ?? '获取可选号码失败,请重试');
    }
  }

  async function loadCardPool(code: string, _p?: string, _v?: string, _c?: string) {
    setSelectCard(undefined);
    setCardPool([]);
    try {
      let res = await apiGetCardPool(code, _p, _v, _c);
      let data = res.data;
      if (data.code === 200) {
        setCardPool(data.data);
        setselectPhoneFlag(true);
      } else {
        setselectPhoneFlag(false);
        setmessageDialogShow(data.msg ?? '获取可选号码失败,请重试');
      }
    } catch (e: any) {
      showToast(e.message ?? '获取可选号码失败,请重试');
    }
  }

  function handleCityPickerChange(v: string[]) {
    if (v[0] === '暂无可选城市') return;
    setProvince(v[0]);
    setCity(v[1]);
    setArea(v[2]);
    if (goodsDetail?.ifAddress === 'Y') {
      loadCardPool(goodsDetail?.goodsCode, v[0], v[1], v[2]);
    }
  }

  async function handleSubmit() {
    console.log('payType', payType);
    /// 身份证正则
    const cardNumReg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (goodsDetail?.ifCampOn === 'Y' && !selectCard) {
      return showToast('请选择号码');
    }
    if (operatorsList.length > 0 && goodsDetail?.goodsType && goodsDetail?.goodsType !== 'order' && !operatorsType) {
      return showToast('请选择运营商');
    }
    if (!cardName) {
      return showToast('请输入身份证名');
    }
    if (!cardNum && goodsDetail?.goodsType === 'order') {
      return showToast('请输入身份证号');
    } else if (goodsDetail?.goodsType === 'order' && !cardNumReg.test(cardNum)) {
      return showToast('请输入正确的身份证号');
    }
    let reg = /^1[3456789]\d{9}$/;
    if (!reg.test(phone)) {
      return showToast('请输入正确的收件号码');
    }
    if (!province) {
      return showToast('请选择省市区');
    }
    if (!address) {
      return showToast('请输入详细地址');
    }
    if (goodsDetail?.ifPhoto === 'Y' && !cardAvatar) {
      return showToast('请上传身份证头像照片');
    }
    if (goodsDetail?.ifPhoto === 'Y' && !cardNationalEmblem) {
      return showToast('请上传身份证国徽照片');
    }
    if (goodsDetail?.ifPhoto === 'Y' && !handPhoto) {
      return showToast('请上传免冠正面照片');
    }
    if (!checkProtocol) {
      return showToast('请阅读并同意协议');
    }
    if (loading) {
      return;
    }
    loading = true;
    let submitOverTips = '';

    let _p = parseLocationParams(location.search) ?? {};
    let orderData = {
      goodsId: goodsDetail!.id,
      cardName,
      cardNo: cardNum,
      receiverName: cardName,
      receiverPhone: phone,
      province,
      city,
      county: area,
      address,
      activatePhone: selectCard?.activatePhone,
      avatarImg: cardAvatar,
      nationalEmblemImg: cardNationalEmblem,
      handPhotoImg: handPhoto,
      operatorsType: operatorsType,
      apiParams: Object.assign({}, urlQueryParams ?? {}, {
        preCityCode: selectCard?.checkCode,
        checkCode: selectCard?.checkCode,
        code: _p.id,
        phoneSessionId: selectCard?.phoneSessionId,
        phoneTag: selectCard?.phoneTag,
        numberId: selectCard?.numberId,
        numberPoolId: selectCard?.numberPoolId,
      }),
    };
    // 非支付订单
    if (goodsDetail?.paySwitch !== 'Y') {
      try {
        let res = await apiSubmitOrder(orderData);
        let data = res.data;
        if (data.code === 200) {
          submitOverTips = data.msg ?? '提交成功';

          navigate('/submit', { state: submitOverTips });
        } else {
          return setmessageDialogShow(data.msg ?? '提交失败');
        }
      } catch (e: any) {
        return showToast(submitOverTips);
      } finally {
        loading = false;
      }
    } else {
      // =================================支付订单===================================
      // if (!isWeixinEnv()) {
      //   return showToast('请在微信中打开页面');
      // }
      if (isWeixinEnv()) {
        if (!wxReady || !main.openId) {
          return showToast('微信支付初始化失败，请刷新重试');
        }
      }
      try {
        let res = await apiPayAndSubmitOrder({
          openId: main.openId,
          appId: main.appId,
          payType: payType,
          clientOrderBo: orderData,
        });
        let data = res.data;
        if (data.code === 200) {
          let _data = data.data;
          let payRes = await showPayPage(_data, payType);
          if (payRes.code === 200) {
            submitOverTips = payRes.msg ?? '提交成功';
            navigate('/submit', { state: submitOverTips });
          } else if (payRes.code === 300) {
            console.log('payRes', payRes);
            window.location.href = payRes.url;
          } else {
            showToast(payRes.msg);
          }
        } else {
          return setmessageDialogShow(data.msg ?? '提交失败');
        }
      } catch (e: any) {
        submitOverTips = e.message ?? '提交失败';
        return showToast(submitOverTips);
      } finally {
        loading = false;
      }
    }
    setSelectCard(undefined);
    setCardName('');
    setCardNum('');
    setPhone('');
    setAddress('');
    setCardAvatar('');
    setNationalEmblem('');
    setHandPhoto('');
  }
  // 存量提交
  async function handleSubmitCL() {
    if (!phone) {
      return showToast('请输入收件人手机号');
    }
    if (!verifyCode) {
      return showToast('请输入验证码');
    }
    try {
      let res = await apiMarketOfferOrder({
        phoneNum: phone,
        verifyCode: verifyCode,
        goodsCode: goodsDetail?.goodsCode,
        orderSn: smsKey,
      });
      let data = res.data;
      if (data.code === 200) {
        showToast('提交成功');
        setPhone('');
        setVerifyCode('');
        setSendCaptcha(0);
      } else {
        setmessageDialogShow(data.msg ?? '提交失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '提交失败');
    }
  }
  // 发送验证码
  async function getSendCaptcha() {
    let telReg = /^1[3-9]{1}[0-9]{9}$/;
    if (!telReg.test(phone)) {
      return showToast('手机号格式不正确');
    }
    try {
      let res = await apiMarketVerifyCode(phone, goodsDetail!.goodsCode);
      let data = res.data;
      if (data.code === 200) {
        if (data?.data?.orderId) {
          setSmsKey(data.data.orderId);
        }
        if (ref.current.timer) {
          clearInterval(ref.current.timer);
        }
        ref.current.count = 60;
        ref.current.timer = setInterval(() => {
          ref.current.count! -= 1;
          setSendCaptcha(ref.current.count!);
          if (ref.current.count! <= 0) {
            clearInterval(ref.current.timer);
          }
        }, 1000);
        showToast('发送成功');
      } else {
        let msg =
          goodsDetail?.goodsType === 'market' && goodsDetail?.supId === '1729792961245667330'
            ? data.data.tipsMsg
            : data.msg;
        setmessageDialogShow(msg ?? '发送失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '发送失败');
    }
  }

  function handleDetailRef(v?: HTMLDivElement | null) {
    if (v) {
      let imgList = v.getElementsByTagName('img');
      if (imgList.length) {
        for (let i = 0; i < imgList.length; i++) {
          let img = imgList.item(i);
          if (img) {
            let url = img.getAttribute('data-href');
            if (url) {
              if (!url?.startsWith('http')) {
                //不是http开头的 跳转到固定页面并传参数
                let tmp = protocolData.find((i) => i.value === url);
                if (tmp) {
                  img.onclick = () => navigate('/protocol', { state: url });
                }
              } else {
                // 是HTTP开头的 跳转走
                if (!img.onclick) {
                  img.onclick = () => window.open(url!);
                }
              }
            }
          }
        }
      }
    }
  }
  function changeVideo(event: any) {
    const video: any = document.getElementById('videoPlayer');
    let isVideoPlaying = !video.paused && !video.ended && video.readyState > 2;
    setIsVideoPlaying(isVideoPlaying);
    if (isVideoPlaying) {
      video.pause();
    } else {
      video.play();
    }
  }
  function handleCopyHost() {
    let url = window.location.href;
    copyToClipboard(url);
  }
  // 上传照片
  const chooseImg = async (t: FileList | null, type: string) => {
    if (t && t[0]) {
      if (t[0].size / 1024 / 1024 > 1.5) return showToast('照片不可以上传超过1.5MB');
      const res = await apiOssUpload(t[0]);
      if (res) {
        if (type === 'cardAvatar') {
          setCardAvatar(res);
        } else if (type === 'cardNationalEmblem') {
          setNationalEmblem(res);
        } else if (type === 'handPhoto') {
          setHandPhoto(res);
        }
      }
    }
  };

  if (loadError || !goodsDetail) {
    return (
      <Page
        style={Object.assign({}, main.pageBgStyle, { popGesture: 'none' })}
        className="detail-page d-flex-c"
        contentClassName="flex-1 h-1 overflow-y"
        appBar={<AppBar className="bg-transparent" title="商品详情" />}
      >
        {!goodsDetail && !loadError ? (
          <Loading tips={'正在加载中...'} />
        ) : (
          <Empty className="emptybox" pic="/pic/pic_not_found.png" tips="Page not found..." />
        )}
      </Page>
    );
  }
  const excludeRegions = {
    provinces: !!goodsDetail.stopProvince ? goodsDetail.stopProvince : [],
    cities: !!goodsDetail.stopCity ? goodsDetail.stopCity : [],
    district: !!goodsDetail.stopCounty ? goodsDetail.stopCounty : [],
  };
  return (
    <Page
      style={Object.assign({}, main.pageBgStyle, { popGesture: 'none' })}
      className="detail-page d-flex-c"
      contentClassName="flex-1 h-1 d-flex-c"
      appBar={
        hideAppBar ? undefined : (
          <AppBar
            showBack={!!main.shopId}
            onBack={() => navigate(`/${main.shopId ? '?code=' + main.shopId : ''}`)}
            className="bg-transparent"
            title="商品详情"
          />
        )
      }
    >
      <div className="flex-1 h-1 overflow-y mb-70">
        <img
          className="head-img overflow-hidden border-radius-10 m-10 box-shadow"
          src={goodsDetail!.goodsHeadImg}
          alt=""
        />
        {/* 商品类型为存量 只需要提交手机号与验证码 */}
        {goodsDetail.goodsType === 'market' ? (
          <div className="form-block m-10 border-radius-10 bg-white p-10 box-shadow">
            <div className="font-size-18 font-widget-600 text-center mb-10">{goodsDetail!.goodsName}</div>
            <div className="prop-line mb-10 d-flex-r ai-center">
              <div className="label">手机号</div>
              <div className="value border-bottom flex-1">
                <input
                  disabled={goodsDetail.goodsStatus === 'stop_sale'}
                  className="input"
                  placeholder="请输入收件手机号"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="prop-line mb-10 d-flex-r ai-center">
              <div className="label">验证码</div>
              <div
                className={`value border-bottom flex-1 d-flex-r ${
                  goodsDetail.goodsStatus === 'stop_sale' ? 'bg-ccc' : ''
                }`}
              >
                <input
                  disabled={goodsDetail.goodsStatus === 'stop_sale'}
                  className="flex-1 input"
                  placeholder="请输入验证码"
                  value={verifyCode}
                  onChange={(e) => setVerifyCode(e.target.value)}
                />
                {goodsDetail.goodsStatus === 'stop_sale' ? (
                  <div className="verify-btn" style={{ background: '#ccc' }}>
                    发送
                  </div>
                ) : (
                  <div
                    className={`verify-btn ${sendCaptcha > 0 && 'disable-verify-btn'}`}
                    onClick={() => {
                      sendCaptcha > 0 ? showToast('短信正在发送，请稍等') : getSendCaptcha();
                    }}
                  >
                    {sendCaptcha > 0 ? `${sendCaptcha}s` : '发送'}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="form-block m-10 border-radius-10 bg-white p-10 box-shadow">
            <div className="font-size-18 font-widget-600 text-center mb-10">{goodsDetail!.goodsName}</div>
            <div className="prop-line mb-10 d-flex-r ai-center">
              <div className="label">收件姓名</div>
              <div className="value border-bottom flex-1">
                <input
                  disabled={goodsDetail.goodsStatus === 'stop_sale'}
                  className="input"
                  placeholder="请输入收件姓名"
                  value={cardName}
                  onChange={(e) => setCardName(e.target.value)}
                />
              </div>
            </div>
            {goodsDetail?.goodsType === 'order' && (
              <div className="prop-line mb-10 d-flex-r ai-center">
                <div className="label">身份证号</div>
                <div className="value border-bottom flex-1">
                  <input
                    disabled={goodsDetail.goodsStatus === 'stop_sale'}
                    className="input"
                    placeholder="请输入身份证号"
                    value={cardNum}
                    onChange={(e) => setCardNum(e.target.value)}
                  />
                </div>
              </div>
            )}

            <div className="prop-line mb-10 d-flex-r ai-center">
              <div className="label">收件号码</div>
              <div className="value border-bottom flex-1">
                <input
                  disabled={goodsDetail.goodsStatus === 'stop_sale'}
                  className="input"
                  placeholder="请输入收件号码"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="prop-line mb-10 d-flex-r ai-center">
              <div className="label">城市选择</div>
              <div className="value border-bottom flex-1">
                <MCityPicker
                  matchList={matchList}
                  disable={goodsDetail.goodsStatus === 'stop_sale'}
                  onChange={handleCityPickerChange}
                  someProvince={goodsDetail!.localAddress ?? []}
                  excludeRegions={excludeRegions}
                  subProvince={!!goodsDetail.subProvince ? goodsDetail.subProvince : []}
                />
              </div>
            </div>
            <div className="prop-line mb-5 d-flex-r ai-center">
              <div className="label">详细地址</div>
              <div className="value border-bottom flex-1">
                <input
                  disabled={goodsDetail.goodsStatus === 'stop_sale'}
                  className="input"
                  placeholder="请输入详细地址"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
            {!!productId && goodsDetail?.supId === '1816357513598500865' && (
              <div className="prop-line mb-5 d-flex-r ai-center">
                <div className="label"></div>
                <div className="flex-1 text-color-red">
                  单一地址中禁止含有:酒吧、宾馆、超市、门卫、开发区、经济开发区、园、大厦、写字楼、酒店、网、茶、游戏厅、台球厅、美容院、饭馆、住所、娱乐性场所、初中，小学、幼儿园等地址为学校、附近、旁边、东西南北、物流、快递、代收点等信息会进行订单拦截，请谨慎下单!
                </div>
              </div>
            )}
            {operatorsList.length > 0 && goodsDetail?.goodsType && goodsDetail?.goodsType !== 'order' && (
              <div className="prop-line mb-5 d-flex-r ai-center">
                <div className="label">运营商</div>
                <div className="value border-bottom flex-1">
                  <MSelectbox
                    disable={goodsDetail.goodsStatus === 'stop_sale'}
                    title="运营商选择"
                    onChange={(v) => v && setoperatorsType(v[0].value)}
                    someProvince={operatorsList}
                    defaultVal={operatorsList[0]?.label}
                  ></MSelectbox>
                </div>
              </div>
            )}
            {goodsDetail!.ifCampOn === 'Y' && (
              <div className="prop-line mb-10 d-flex-r ai-center">
                <div className="label">在线选号</div>
                <div
                  className={`value border-bottom flex-1 ${selectCard ? '' : 'text-color-9'}  
                  ${goodsDetail.goodsStatus === 'stop_sale' ? 'bg-555 pl-10' : ''}`}
                  onClick={() => {
                    if (goodsDetail!.ifAddress === 'Y' && !province) {
                      showToast('请先选择城市');
                      return;
                    }
                    setShowSelectCardDialog(true);
                  }}
                >
                  {selectCard?.activatePhone || (goodsDetail!.ifAddress === 'Y' ? '需要先选择城市' : '请选择号码')}
                </div>
              </div>
            )}
            {goodsDetail!.ifPhoto === 'Y' && (
              <div className="prop-line mb-10 d-flex-r ai-center">
                <div className="label">身份证头像面</div>
                <div className="value border-bottom flex-1 pic pb-5 d-flex-r">
                  {cardAvatar ? (
                    <div
                      className="upload-pic border-radius-4 overflow-hidden mr-5"
                      onClick={() => setCardAvatar(undefined)}
                    >
                      <img className="bw-100 bh-100" src={cardAvatar} alt="" />
                      <div className="icon-close-block">
                        <img className="bw-100 bh-100" src="/pic/icon_close.png" alt="" />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`upload-pic border border-radius-4 text-center ${
                        goodsDetail.goodsStatus === 'stop_sale' && 'bg-555'
                      }`}
                    >
                      <img className="icon-12" src="/pic/icon_plus.png" alt="" />
                      <input
                        disabled={goodsDetail.goodsStatus === 'stop_sale'}
                        className="upload-input bw-100 bh-100"
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        onChange={(e) => {
                          chooseImg(e.target.files, 'cardAvatar');
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {goodsDetail!.ifPhoto === 'Y' && (
              <div className="prop-line mb-10 d-flex-r ai-center">
                <div className="label">身份证国徽面</div>
                <div className="value border-bottom flex-1 pic pb-5 d-flex-r">
                  {cardNationalEmblem ? (
                    <div
                      className="upload-pic border-radius-4 overflow-hidden mr-5"
                      onClick={() => setNationalEmblem(undefined)}
                    >
                      <img className="bw-100 bh-100" src={cardNationalEmblem} alt="" />
                      <div className="icon-close-block">
                        <img className="bw-100 bh-100" src="/pic/icon_close.png" alt="" />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`upload-pic border border-radius-4 text-center ${
                        goodsDetail.goodsStatus === 'stop_sale' && 'bg-555'
                      }`}
                    >
                      <img className="icon-12" src="/pic/icon_plus.png" alt="" />
                      <input
                        disabled={goodsDetail.goodsStatus === 'stop_sale'}
                        className="upload-input bw-100 bh-100"
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        onChange={(e) => {
                          chooseImg(e.target.files, 'cardNationalEmblem');
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {goodsDetail!.ifPhoto === 'Y' && (
              <div className="prop-line mb-10 d-flex-r ai-center">
                <div className="label">免冠正面照片</div>
                <div className="value border-bottom flex-1 pic pb-5 d-flex-r">
                  {handPhoto ? (
                    <div
                      className="upload-pic border-radius-4 overflow-hidden mr-5"
                      onClick={() => setHandPhoto(undefined)}
                    >
                      <img className="bw-100 bh-100" src={handPhoto} alt="" />
                      <div className="icon-close-block">
                        <img className="bw-100 bh-100" src="/pic/icon_close.png" alt="" />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`upload-pic border border-radius-4 text-center ${
                        goodsDetail.goodsStatus === 'stop_sale' && 'bg-555'
                      }`}
                    >
                      <img className="icon-12" src="/pic/icon_plus.png" alt="" />
                      <input
                        disabled={goodsDetail.goodsStatus === 'stop_sale'}
                        className="upload-input bw-100 bh-100"
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        onChange={(e) => {
                          chooseImg(e.target.files, 'handPhoto');
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="d-flex-r pb-10">
              <MCheckbox value={checkProtocol} onChange={(v) => setCheckProtocl(v)} />
              <div className="ml-10 font-size-12">
                <span onClick={() => setCheckProtocl(!checkProtocol)}>我已阅读并同意</span>
                <span className="text-color-p" onClick={() => setShowProtocol('userInfo')}>
                  《关于客户个人信息收集、使用规则的公告》
                </span>
                {goodsDetail.operatorsType === 'telecom' && (
                  <span className="text-color-p" onClick={() => setShowProtocol('noticeDX')}>
                    《中国电信用户入网协议》
                  </span>
                )}
                <span className="text-color-p" onClick={() => setShowProtocol('phone')}>
                  《办理电话号码开户法律风险及防范提示告知书》
                </span>
                {goodsDetail.operatorsType === 'cmcc' && (
                  <span className="text-color-p" onClick={() => setShowProtocol('noticeYD')}>
                    《关于客户个人信息收集、使用及保护的公告》
                  </span>
                )}
              </div>
            </div>
          </div>
        )}

        {goodsDetail.paySwitch === 'Y' && (
          <div className="form-block m-10 border-radius-10 bg-white p-10 box-shadow">
            <div className="font-widget-600">选择支付方式</div>
            {payConfigList.map((i) => (
              <div className="d-flex-r ai-center mt-14">
                <img src={`/pic/${PayType.typeIcon(payType)}`} className="icon-20 mr-10" alt="" />
                <div className="flex-1">{i.name}</div>

                <MCheckbox value={i.payType === payType} onChange={() => setPayType(i.payType)} />
              </div>
            ))}
            {!isWeixinEnv() && (
              <div className="mt-10">
                <span className="font-size-12 text-color-6">如需微信支付，请复制链接到微信打开即可！</span>
                <div
                  className="bg-p w-100 border-radius-4 text-color-white pt-5 pb-5 text-center mt-5"
                  onClick={handleCopyHost}
                >
                  复&nbsp;制
                </div>
              </div>
            )}
          </div>
        )}
        <div className="pl-30 pr-30 font-size-12 text-color-9">
          *请填写真实身份信息，未满18岁、一证五号、信息错误将无法发货。(所填信息皆做加密处理)
        </div>
        <div className="box-shadow bg-white border-radius-10 m-10">
          {!!goodsDetail?.videoUrl && (
            <div className="video-box" onClick={changeVideo}>
              <video
                width="100%"
                id="videoPlayer"
                // autoPlay
                webkit-playsinline="true"
                playsInline={true}
                // controls
                poster={!!isVideoPlaying ? goodsDetail!.goodsHeadImg : ''}
              >
                <source src={goodsDetail?.videoUrl} type="video/mp4" />
              </video>
              {!!isVideoPlaying && <img className="video-img" src="/pic/icon-video.png" alt="" />}
            </div>
          )}
          <div className="font-size-18 font-widget-600 text-center p-10">详情介绍</div>
          <div
            className="goods-detail pb-20"
            ref={(v) => handleDetailRef(v)}
            dangerouslySetInnerHTML={{ __html: goodsDetail!.goodsDetail }}
          />
        </div>
      </div>
      {goodsDetail.goodsStatus === 'stop_sale' ? (
        <div className="p-10">
          <div className="submit-btn" style={{ background: '#ccc' }}>
            该商品暂不可下单!
          </div>
        </div>
      ) : (
        <div className="p-10 footer-btn">
          {goodsDetail.goodsType === 'market' ? (
            <div className="submit-btn" onClick={handleSubmitCL}>
              提交
            </div>
          ) : (
            <div className="submit-btn" onClick={handleSubmit}>
              {goodsDetail.paySwitch === 'Y' ? `立即支付￥${goodsDetail.goodsPrice}` : '提交'}
            </div>
          )}
        </div>
      )}

      {showProtocol && (
        <ProtocolDialog supName={goodsDetail!.supName} type={showProtocol} onClose={() => setShowProtocol(undefined)} />
      )}
      {showSelectCardDialog && (
        <SelectPhoneDialog
          list={cardPool ?? []}
          value={selectCard}
          selectPhoneFlag={selectPhoneFlag}
          onChange={(v) => setSelectCard(v)}
          onClose={() => setShowSelectCardDialog(false)}
          onChangeNextPage={() => loadCardPool(goodsDetail?.goodsCode, province, city, area)}
        />
      )}
      {/* 接口500 返回的msg弹窗 */}
      {!!messageDialogShow && <MessageDialog message={messageDialogShow} onClose={() => setmessageDialogShow('')} />}
    </Page>
  );
}
