import { useEffect, useRef, useState } from 'react';
import AwesomeSwiper from 'react-awesome-swiper';
import { useLocation } from 'react-router-dom';
import { apiGetGoodsList, apiGetH5Config } from '../api';
import GoodsItem from '../components/goods-item';
import Page from '../components/page';
import TabBar from '../components/tabBar';
import main from '../main';
import { BannerItem, Goods, H5ConfigJson, SelectOption } from '../types';
import { parseLocationParams } from '../utils/utils';

enum OperatorsType {
  /** 推荐 */
  all = 'all',
  /** 中国广电 */
  cbn = 'cbn',
  /** 中国电信 */
  telecom = 'telecom',
  /** 中国联通 */
  unicom = 'unicom',
  /** 中国移动 */
  cmcc = 'cmcc',
}

const tabOptions: Array<SelectOption> = [
  { label: '推荐', value: OperatorsType.all },
  { label: '广电', value: OperatorsType.cbn },
  { label: '电信', value: OperatorsType.telecom },
  { label: '联通', value: OperatorsType.unicom },
  { label: '移动', value: OperatorsType.cmcc },
];
const tabDefault = tabOptions[0];

export default function HomePage() {
  const location = useLocation();
  const [tabValue, setTabValue] = useState(tabDefault);

  const [goodsList, setGoodsList] = useState<Array<Goods>>([]);
  const [h5Config, setH5Config] = useState<H5ConfigJson>({});
  const swiperRef = useRef<AwesomeSwiper | null>(null);
  const config = {
    loop: true,
    autoplay: { delay: 3000 },
    lazy: true,
    speed: 500,
    pagination: {
      el: '.swiper-pagination',
      bulletActiveClass: 'dot-active',
      bulletElement: 'li',
      hideOnClick: false,
      clickable: true,
    },
  };

  useEffect(() => {
    let { code } = parseLocationParams(location.search) ?? {};
    if (code) {
      main.shopId = code;
      sessionStorage.setItem('landingCode', code);
    }
  }, [location]);

  useEffect(() => {
    loadH5Config();
    loadGoodsList();
  }, []);

  async function loadH5Config() {
    try {
      let res = await apiGetH5Config();
      let data = res.data;
      if (data.code === 200) {
        let _data = data.data || {};
        setH5Config(_data);
        main.serviceLink = _data.serviceLink;
      }
    } catch (e) {}
  }

  async function loadGoodsList() {
    try {
      let res = await apiGetGoodsList();
      let data = res.data;
      if (data.code === 200) {
        setGoodsList(data.data);
      }
    } catch (e: any) {}
  }
  let list = goodsList || [];
  if (tabValue.value !== OperatorsType.all) {
    list = list.filter((i) => i.operatorsType === tabValue.value);
  }

  let bannerList = (h5Config.bannerImgList ?? []).filter((i: BannerItem) => !!i?.label);

  return (
    <Page className="home-page d-flex-c" contentClassName="flex-1 overflow-y" navPage style={main.pageBgStyle}>
      <div className="banner-block">
        {!!bannerList?.length && (
          <AwesomeSwiper className="m-swiper" ref={(v) => (swiperRef.current = v)} config={config}>
            <div className="swiper-wrapper">
              {bannerList.map((i: BannerItem) => (
                <img
                  key={i.label}
                  className="swiper-slide pic-size"
                  src={i.label}
                  alt=""
                  onClick={() => {
                    if (i.value) {
                      window.open(i.value);
                    }
                  }}
                />
              ))}
            </div>
            <div className="swiper-pagination dot-block"></div>
          </AwesomeSwiper>
        )}
        {h5Config.tipsMsg && <div className="tips-block text-center font-size-12">{h5Config.tipsMsg}</div>}
      </div>
      <TabBar
        className="box-shadow m-10 border-radius-10"
        options={tabOptions}
        value={tabValue}
        onChange={(v) => setTabValue(v)}
      />
      <div className="d-flex-r flex-wrap pl-10 pt-10 mb-50">
        {list.map((i) => (
          <GoodsItem key={i.id} goods={i} />
        ))}
      </div>
    </Page>
  );
}
